<template>
  <v-container fluid fillheight>
    <v-row>
      <v-col v-if="$store.state.participantsModule.isChildrenList" :cols="3">
        <children-list />
      </v-col>
      <v-col :cols="$store.state.participantsModule.isChildrenList ? 9 : 12">
        <v-banner
          v-if="!$store.state.participantsModule.isChildSelected"
          single-line
        >
          <v-icon slot="icon" color="warning" size="36">
            mdi-gesture-double-tap
          </v-icon>
          Bitte Teilnehmer auswählen oder anlegen!
        </v-banner>
        <router-view v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import EditChild from './parts/EditChild.vue';
import ChildrenList from './parts/ChildrenList.vue';

import participantsModule from '../../store/modules/participants';

export default {
  components: {
    // EditChild,
    ChildrenList
  },

  data() {
    return {
      snackbar: {
        text: '',
        show: false,
        color: 'green'
      }
    };
  },

  computed: {},

  created() {
    /* ensure that the store module for login is registered */
    if (!this.$store.hasModule('participantsModule')) {
      this.$store.registerModule('participantsModule', participantsModule);
    }
    this.readParticipants();
  },

  methods: {
    readParticipants() {
      this.$store.dispatch('ACT_GET_PARTICIPANTS').catch(error => {
        this.$root.$info.open(error);
      });
    },

    showSnackBar(clr, txt) {
      this.snackbar.show = true;
      this.snackbar.color = clr;
      this.snackbar.text = txt;
    }
  }
};
</script>
