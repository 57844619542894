var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"color":"#F9F7F7","dense":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","prepend-icon":"mdi-magnify","single-line":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Teilnehmer suchen")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$store.state.loginModule.isAdmin)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.openEditDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Teilnehmer hinzufügen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue"},on:{"click":_vm.readParticipants}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Liste neu lesen")])])],1),(_vm.children.length > 0)?_c('v-list',{staticClass:"overflow-y-auto",attrs:{"rounded":"","max-height":"400"}},[_c('v-list-item-group',{attrs:{"active-class":"grey--text"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.children),function(item,index){return [_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.selectChild(item.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.firstName))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.familyName))])],1)],1),(index < _vm.children.length - 1)?_c('v-divider',{key:index,staticClass:"mx-2 mb-2"}):_vm._e()]})],2)],1):_c('div',[_vm._v("Keine Daten")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }