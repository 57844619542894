<template>
  <v-card>
    <v-toolbar color="#F9F7F7" dense class="mb-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="filter"
            hide-details
            prepend-icon="mdi-magnify"
            single-line
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <span>Teilnehmer suchen</span>
      </v-tooltip>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="$store.state.loginModule.isAdmin"
            icon
            color="green"
            v-bind="attrs"
            @click="openEditDialog()"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Teilnehmer hinzufügen</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="blue"
            v-bind="attrs"
            @click="readParticipants"
            v-on="on"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Liste neu lesen</span>
      </v-tooltip>
    </v-toolbar>
    <v-list
      v-if="children.length > 0"
      rounded
      max-height="400"
      class="overflow-y-auto"
    >
      <v-list-item-group v-model="selected" active-class="grey--text">
        <template v-for="(item, index) in children">
          <v-list-item :key="item.id" @click="selectChild(item.id)">
            <v-list-item-content>
              <v-list-item-title>{{ item.firstName }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ item.familyName }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="index < children.length - 1"
            :key="index"
            class="mx-2 mb-2"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>

    <div v-else>Keine Daten</div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      filter: ''
    };
  },

  computed: {
    children() {
      if (!this.$store.state.participantsModule.participants) {
        return [];
      }
      const data = this.$store.state.participantsModule.participants;

      if (this.filter.length > 0) {
        return data.filter(elem =>
          `${elem.firstName} ${elem.familyName}`.includes(this.filter)
        );
      }
      return data;
    }
  },

  methods: {
    selectChild(id) {
      this.$store.commit('MUT_IS_CHILD_SELECTED', true);
      this.$router.push({ path: `/participants/display/${id}` });
    },

    readParticipants() {
      this.$store.dispatch('ACT_GET_PARTICIPANTS').catch(error => {
        this.$root.$info.open(error);
      });
    },

    openEditDialog() {
      this.$store.commit('MUT_IS_CHILD_SELECTED', true);
      this.$store.commit('MUT_IS_CHILDREN_LIST', false);
      this.$router.push('/participants/create');
    }
  }
};
</script>
