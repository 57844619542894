import Vue from 'vue';
import Vuex from 'vuex';

const Parse = require('parse');

Parse.serverURL = process.env.VUE_APP_PARSE_SERVER_URL;
Parse.initialize(process.env.VUE_APP_PARSE_APP_ID);

Vue.use(Vuex);

function setChildObject(isCreate, participant, child) {
  const keys = Object.keys(participant);
  keys.forEach(key => {
    child.set(key, participant[key]);
  });
  if (isCreate) {
    child.set('createdBy', Parse.User.current().id);
  } else {
    child.set('modifiedBy', Parse.User.current().id);
  }
  return child;
}

const mutations = {
  MUT_CREATE_PARTICIPANT(state, object) {
    state.participants.push(object);
  },

  MUT_UPDATE_PARTICIPANT(state, object) {
    const index = state.participants.findIndex(
      element => element.id === object.id
    );
    state.participants.splice(index, 1, object);
  },

  MUT_DELETE_PARTICIPANT(state, object) {
    const index = state.participants.findIndex(
      element => element.id === object.id
    );
    state.participants.splice(index, 1);
  },

  MUT_GET_PARTICIPANTS(s, object) {
    const state = s;
    state.participants = object.data;
  },

  MUT_IS_CHILD_SELECTED(s, isChildSelected) {
    const state = s;
    state.isChildSelected = isChildSelected;
  },

  MUT_IS_CHILDREN_LIST(s, isChildrenList) {
    const state = s;
    state.isChildrenList = isChildrenList;
  }
};

const actions = {
  async ACT_CREATE_PARTICIPANT({ commit }, participant) {
    const registrant = participant; // cloud function expects registrant
    const isNotExisting = await Parse.Cloud.run('checkRegistrantExistance', {
      registrant
    });

    if (!isNotExisting) {
      return Promise.reject(
        new Error(`
      Ein Teilnehmer ${participant.firstName} ${participant.familyName} (geb.${participant.birthDay})\n
      existiert bereits.`)
      );
    }

    try {
      const Child = Parse.Object.extend('Child');
      // create the child
      await setChildObject(true, participant, new Child()).save();
      commit('MUT_CREATE_PARTICIPANT', participant);
      return participant;
    } catch (error) {
      return Promise.reject(new Error(error));
    }
  },

  async ACT_UPDATE_PARTICIPANT({ commit }, participant) {
    try {
      const Child = Parse.Object.extend('Child');
      const query = new Parse.Query(Child);
      const existingChild = await query.get(participant.id);
      await setChildObject(false, participant, existingChild).save();
      commit('MUT_UPDATE_PARTICIPANT', participant);
      return participant;
    } catch (error) {
      return Promise.reject(new Error(error));
    }
  },

  async ACT_DELETE_PARTICIPANT({ commit }, participant) {
    const query = new Parse.Query(Parse.Object.extend('Child'));
    await query.get(participant.id).then(object => {
      object.destroy();
      commit('MUT_DELETE_PARTICIPANT', participant);
    });
  },

  async ACT_GET_PARTICIPANTS({ commit }) {
    const Child = Parse.Object.extend('Child');
    const query = new Parse.Query(Child);
    query.limit(500);

    await query.find().then(results => {
      const data = [];
      results.forEach(e => {
        const participant = { id: e.id };
        Object.keys(e.attributes).forEach(key => {
          participant[key] = e.attributes[key];
        });
        data.push(participant);
      });
      commit('MUT_GET_PARTICIPANTS', {
        data
      });
    });
  }
};

const state = {
  participants: [],
  isChildSelected: false,
  isChildrenList: true
};

export default {
  state,
  mutations,
  actions
};
